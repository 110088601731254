import { Outlet } from "react-router";

function UsersRoute() {
  return (
    <>
      <Outlet />
    </>
  )
}

export default UsersRoute;
